.contact_form__container {
    position: relative;
    padding: 50px 10%;
    opacity: 0;
    max-width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 10%;
    row-gap: 35px;
}

.contact_form__container h1 {
    grid-column: span 2;
    text-align: center;
    font-family: presti-display, serif;
    font-weight: 500;
    font-style: italic;
}

.contact_form__container .contact__details {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    width: 100%;
    max-height: 100%;
    gap: 20px;
}

.contact_form__container .contact__details .contact__detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-height: 100%;
    border-radius: 15px;
    border: 1px solid var(--primary-color-ultra-light);
    padding: 20px;
    cursor: pointer;

    transition: all 350ms ease-in-out;
}

.contact_form__container .contact__details .contact__detail:hover {
    background-color: var(--primary-color-ultra-light);
}

.contact_form__container .contact__details .contact__detail svg {
    font-size: 1.5rem;
    margin-bottom: 15px;
}

.contact_form__container .contact__details .contact__detail .mini_title {
    font-size: 1rem;
    font-weight: 500;
}

.contact_form__container .contact__details .contact__detail p {
    margin-bottom: 15px;
}

.contact_form__container .contact__details .contact__detail .button {
    width: 60%;
    background-color: var(--primary-black);
    color: white;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    font-family: "novecento-sans-wide", sans-serif;
    font-weight: 200;
    font-style: normal;
    font-size: 0.85rem;
    border-radius: 15px;
    align-self: center;
}

/*  SECTION ANIMATIONS  */

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/*  !SECTION  */

@media only screen and (max-width: 768px) {

    .contact_form__container {
        padding: 120px 20px 100px 20px;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 175px;
        overflow-x: hidden;
        gap: 25px;
        column-gap: 0;
    }
    
    .contact_form__container h1 {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.7rem;
    }

    .contact_form__container #contact-form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 100%;
        width: 100%;
    }

    .contact_form__container .textarea_wrapper {
        height: 10%;
        max-height: 150px;
    }

    .contact_form__container form .input_combo {
        width: 100%;
        column-gap: 5px;
    }

    .contact_form__container form .input_combo .input_wrapper {
        width: calc(50% - 2.5px);
    }

    .contact_form__container form .input_wrapper {
        max-width: 100%;
    }
    
    .contact_form__container .contact__details {
        display: none;
    }
    
    .contact_form__container .contact__details .contact__detail {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        max-height: 100%;
        border-radius: 15px;
        border: 1px solid var(--secondairy-light-gray);
        padding: 20px;
        cursor: pointer;
    
        transition: all 350ms ease-in-out;
    }
    
    .contact_form__container .contact__details .contact__detail:hover {
        background-color: var(--secondairy-light-gray);
    }
    
    .contact_form__container .contact__details .contact__detail svg {
        font-size: 1.5rem;
        margin-bottom: 15px;
    }
    
    .contact_form__container .contact__details .contact__detail .mini_title {
        font-size: 1rem;
        font-weight: 500;
    }
    
    .contact_form__container .contact__details .contact__detail p {
        margin-bottom: 15px;
    }
    
    .contact_form__container .contact__details .contact__detail .button {
        width: 60%;
        background-color: var(--primary-black);
        color: white;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: flex-end;
        font-family: "novecento-sans-wide", sans-serif;
        font-weight: 200;
        font-style: normal;
        font-size: 0.85rem;
        border-radius: 15px;
        align-self: center;
    }
    

}
