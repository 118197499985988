.news_overview__page .news_overview_body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.news_overview__page .news_overview_body .page_title {
    text-align: center;
    margin: 50px 0 100px 0;
    font-size: 3.2rem;
    text-transform: capitalize;
    color: var(--primary-color);
}

.news_overview__page .news_overview_body .news_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 25px 0;
    gap: 25px;
    width: 70vw;
}

.news_overview__page .news_overview_body .news_item {
    position: relative;
    height: 40vh;
    width: 100%;
    overflow: hidden;
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    background-color: rgba(0, 0, 0, 0.125);
}

.news_overview__page .news_overview_body .news_item.featured {
    height: 40vh;
    width: 70vw;
}

.news_overview__page .news_overview_body .news_item img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    transition: transform 400ms ease-in-out;
    transform-origin: center center;
    z-index: -1;
}

.news_overview__page .news_overview_body .news_item img.hovered {
    transform: scale(1.05);
}

.news_overview__page .news_overview_body .news_item .padding {
    padding: 20px 20px 80px 20px;
}

.news_overview__page .news_overview_body .news_details {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 25px;
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: white;

    transition: all 250ms ease-in-out;
}

.news_overview__page .news_overview_body .news_details.hovered {
    background-color: rgba(0, 0, 0, 0.50);
}

.news_overview__page .news_overview_body .news_details p {
    margin-top: 7.5px;
}

@media only screen and (max-width: 768px) {


    .news_overview__page .news_overview_body .page_title {
        margin: 125px 0 50px 0;
    }


    .news_overview__page .news_overview_body .news_grid {
        width: calc(100% - 30px);
        gap: 10px;
        padding-top: 10px;
    }

    .news_overview__page .news_overview_body .news_item.featured {
        width: calc(100% - 30px);
    }

}