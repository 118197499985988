* {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

a,
p,
h1,
h2,
h3,
label,
div {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

html,
body {
  overflow: hidden auto;
  margin: 0;
  padding: 0;
  width: 100%;
}

button {
  user-select: none;
}

img {
  user-select: none;
}

/* Custom scrollbar for Chrome, Safari and Opera */

*::-webkit-scrollbar {
  width: 1em;
}

*::-webkit-scrollbar-track {
  background: var(--secondairy-ultra-light-gray);
  border-radius: 10px;
  opacity: 0.5;
  margin-block: 0.5rem;
}

*::-webkit-scrollbar-thumb {
  background: var(--secondairy-light-gray);
  border-radius: 10px;
}

body::-webkit-scrollbar {
  display: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
  color: black;
}


:root {
  --primary-black: rgba(30, 30, 30, 1);
  /*#1E1E1E*/
  --primary-color: rgb(4, 67, 76, 1);
  /*#04434c*/
  --primary-color-light: rgb(20, 144, 163);
  /*#1490A3*/
  --primary-color-ultra-light: rgb(170, 188, 192);
  --secondairy-medium-gray: rgba(95, 95, 95, 1);
  /*#5F5F5F*/
  --secondairy-light-medium-gray: rgb(207, 207, 207);
  --secondairy-light-gray: rgba(235, 235, 235, 1);
  --secondairy-ultra-light-gray: rgba(250, 250, 250, 1);

  --red: rgb(212, 53, 53);
  /* #D43535 */
  --light-red: rgb(250, 210, 210);
  --dark-red: rgb(155, 64, 64);
  /* #9B4040 */
  --ultra-light-red: rgb(250, 79, 79, 0.2);
  --hyper-light-red: rgb(250, 79, 79, 0.1);
  --green: rgb(52, 211, 153);
  --light-green: rgb(209, 250, 229);
  --ultra-light-green: rgb(209, 250, 229, 0.3);
  --hyper-light-green: rgb(209, 250, 229, 0.15);
  --dark-green: rgb(71, 95, 70);

  --border-shadow: rgba(50, 50, 93, 0.2) 0.8px 0.8px 1px 0.5px;
  --border-shadow-min: rgba(50, 50, 93, 0.10) 0.5px 0.5px 1px 0px;
  --border-shadow-float: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  --border-shadow-allround: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
}

/*  GENERICS  */

.hidden {
  display: none !important;
}

.faded_away {
  opacity: 0 !important;
  pointer-events: none;
}

.highlight {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 50%;
  padding: 8px 10px;
}

.underscored {
  text-decoration: underline;
}

.strong {
  font-weight: 500 !important;
}

/*  LOTTIES  */

.scroll_down__icon {
  position: absolute;
  bottom: 10px;
  width: 60px;
  height: 60px;
}

/*  SECTIONS */

section {
  width: 100%;
  display: flex;
  justify-content: center;
  height: fit-content;
  min-height: 100vh;

  position: relative;
  color: var(--primary-color);
  display: flex;
  align-items: center;
}

.section_sub_content {
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-25px);
  transition: all 1150ms ease-in-out;
  /* Add fallback for browsers that don't support transitions well */
  -webkit-transition: all 1150ms ease-in-out;
  -moz-transition: all 1150ms ease-in-out;
  -o-transition: all 1150ms ease-in-out;
}

.section_sub_content.visible {
  width: 100%;
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
}

/* Add a fallback class for browsers without proper transition support */
.section_sub_content.no-animation-fallback {
  visibility: visible !important;
  opacity: 1 !important;
  transform: none !important;
  -webkit-transform: none !important;
}

.section_content {
  display: flex;
  padding: 0 10% 0 0;
}

.content_page_body {
  min-height: 35vh;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.content_page_body .page_title {
  text-align: center;
  margin: 50px 0 100px 0;
  font-size: 3.2rem;
  text-transform: capitalize;
  color: var(--primary-color);
}

.content_page_body .content_page__content {
  max-width: 50vw;
  padding: 25px 0 50px 0;
}

.page_title {
  display: flex;
  align-items: center;
  gap: 15px;
}

.page_title__word {
  display: inline-block;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.page_title__word.visible {
  opacity: 1;
}

.page_title__word.highlight {
  background-image: url('../public/img/brush-stroke.png');
  background-size: 0% 50%;
  background-position: left center;
  transition: background-size 250ms ease-out;
}

.page_title__word.highlight.animated {
  background-size: 100% 50%;
}

/*  TEXT & TITLES  */

.section_title {
  font-size: 4rem;
  text-transform: capitalize;
  margin: 25px 0;
  width: 20%;
  padding: 15px;
  margin-right: 100px;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section_title .highlight {
  background-size: 0% 50%;
  background-position: left center;
  transition: background-size 250ms ease-out;
}

.section_title .highlight.animated {
  background-size: 100% 50%;
}

.section_title__word {
  width: calc(100% + 100px);
  opacity: 0;
  transition: opacity 500ms ease-in-out;
  overflow-x: visible;
}

.section_title__word.visible {
  opacity: 1;
}

.section_sub_title {
  font-size: 2.7rem;
  margin-bottom: 50px;
  width: 100%;
  text-align: left;
  font-family: presti-display, serif;
  font-weight: 500;
  font-style: italic;
  text-transform: capitalize;
  color: white;
  line-height: 3.3rem;
}

.page_sub_title {
  font-size: 2.7rem;
  margin-bottom: 50px;
  width: 100%;
  text-align: center;
  font-family: presti-display, serif;
  font-weight: 600;
  font-style: italic;
  color: var(--primary-color);
  line-height: 3.3rem;
}

.section_text {
  font-size: 1.3rem;
  margin-bottom: 35px;
  width: 100%;
  font-weight: 300;
  line-height: 2rem;
  word-spacing: 0.3rem;
}

/*  BUTTONS  */

.form_submit_button {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding: 20px 15px;
  font-size: 1.2rem;
  font-weight: 700;
  background-color: var(--primary-color-ultra-light);
  color: white;
  outline: none;
  border: none;
  border-radius: 7.5px;
  cursor: pointer;
  letter-spacing: 0.2rem;


  transition: all 300ms ease-in-out;
}

.buttons {
  display: flex;
  gap: 15px;
}

.content_button {
  padding: 7.5px 20px;
  background-color: transparent;
  border: 2px solid var(--primary-color-ultra-light);
  border-radius: 15px;
  color: var(--primary-color-light);
  cursor: pointer;

  transition: all 300ms ease-in-out;
}

.content_button:hover {
  background-color: var(--primary-color-ultra-light);
  border: 2px solid var(--primary-color-ultra-light);
  color: white;
}

/*  FORMS & INPUTS  */

.form_submit_button:hover {
  background-color: var(--primary-black);
  color: white;
}

.input_combo {
  display: flex;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  max-width: 100%;
}

.input_combo .input_wrapper {
  width: 100%;
}

.input_wrapper {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.input_wrapper label {
  font-weight: 400;
  font-style: normal;
  margin-bottom: 10px;
  max-width: 100%;
}

.input_field.text {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding: 10px 10px;
  border: none;
  border-bottom: 1px solid var(--primary-color-ultra-light);
  outline: none;
  height: 45px;
  color: var(--secondairy-medium-gray);
  font-size: 1rem;
  font-weight: 400;
}

.input_field.text::placeholder {
  font-weight: 200;
  color: var(--secondairy-light-medium-gray);
}

.textarea_wrapper {
  display: flex;
  flex: 1;
  min-height: 200px;
}

.input_field.text_area {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding: 10px 10px;
  border: 1px solid var(--primary-color-ultra-light);
  color: var(--secondairy-medium-gray);
  outline: none;
  border-radius: 5px;
  height: 250px;
  font-size: 1rem;
  resize: none;
  flex-grow: 1;
  font-weight: 400;
}

.input_field.text_area::placeholder {
  font-weight: 200;
  color: var(--secondairy-light-medium-gray);
}

@media only screen and (max-width: 768px) {


  .scroll_down__icon {
    bottom: 75px;
  }

  .page_title {
    margin: 125px 0 50px 0;
    max-width: calc(100vw - 50px);
    flex-direction: column;
    gap: 0;
  }

}