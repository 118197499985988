.project_page {
  position: relative;
}

.project_page .image_container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 80vh;
  z-index: -1;
  overflow: hidden;
}

.project_page .main_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project_page .project_page_content_container {
  background-color: transparent;
  margin-top: 0;
  padding-top: 80vh;
  z-index: 1;
  position: relative;
}

.project_page .project_page_content_container .content_button {
  width: 200px;
  padding: 15px;
  font-weight: 500;
  font-size: 1rem;
}

.project_page .project_content_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
  min-height: 30vh;
  background-color: var(--primary-color);
}

.project_page .project_title_description {
  display: flex;
  flex-direction: column;
  padding: 50px 50px;
  max-width: 750px;
  gap: 30px;
  color: white;
}

.project_page .project_title_description h1 {
  font-family: presti-display, serif;
  font-weight: 500;
  font-style: italic;
  text-transform: capitalize;
  font-size: 3.5rem;
}

.project_page .project_title_description p {
  font-weight: 300;
  text-align: justify;
}

.project_page .project_details {
  padding: 60px 60px 60px 60px;
  color: white;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  justify-content: center;
}

.project_page .project_detail {
  display: flex;
  align-items: center;
  gap: 25px;
  font-size: 1.2rem;
}

.project_page .project_detail .detail_content {
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.project_page .project_detail .mini_title {
  font-family: presti-display, serif;
  font-weight: 500;
  font-style: italic;
  font-size: 1.4rem;
  width: 125px;
}

.project_page .project_detail a {
  font-weight: 300;
  color: white;
}

.project_page .content_body {
  min-height: 200vh;
  background-color: white;
  padding: 20px 0;
}

.project_page .images_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 0 20px;
}

.project_page .images_grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease-in-out;
}

.project_page .images_grid img.loading {
  opacity: 0;
}

.project_page .images_grid img.loaded {
  opacity: 1;
}

.grid_item {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
}

.grid_item_span_2x2 {
  grid-column: span 2;
  grid-row: span 2;
}

.grid_item_span_1x1 {
  grid-column: span 1;
  grid-row: span 1;
}

.grid_item_span_2x1 {
  grid-column: span 2;
  grid-row: span 1;
}

.grid_item_span_1x2 {
  grid-column: span 1;
  grid-row: span 2;
}

.grid_item_span_3x1 {
  grid-column: span 3;
  grid-row: span 1;
}

.image_skeleton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #e0e0e0;
  animation: skeleton-loading 1.5s infinite;
}

.project_page .contact_form {
  padding: 50px 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* SECTION IMAGE POPUP */
.project_page .image_popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(56, 49, 49, 0.95);
  z-index: 500;
  pointer-events: none;
  transition: opacity 300ms ease;
}

.project_page .image_popup.active {
  opacity: 1;
  pointer-events: auto;
  z-index: 1000;
}

.project_page .image_popup .closing_icon {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  top: 30px;
  right: 35px;
  width: 40px;
  height: 40px;
  background-color: var(--primary-color-ultra-light);
  color: black;
  font-size: 1.2rem;
  cursor: pointer;
}

.project_page .image_popup .image_popup_content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 5vh 0;
  user-select: none;
}

.project_page .image_popup .image_popup_content img {
  height: 100%;
  max-width: 85%;
  object-fit: contain;
}

.project_page .image_popup .navigation {
  position: absolute;
  display: flex;
  gap: 150px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--primary-color-ultra-light);
  z-index: 501;
  font-size: 1.5rem;
  cursor: pointer;
}

.project_page .image_popup .navigation.hidden {
  display: none;
}

.project_page .navigation.right {
  right: 50px;
}

.project_page .navigation.left {
  left: 50px;
}

@keyframes skeleton-loading {
  0% {
    background-color: #f0ecec;
  }

  50% {
    background-color: #d1d1d1;
  }

  100% {
    background-color: #f0ecec;
  }
}

@media only screen and (max-width: 768px) {
  .project_page .fixed_elements {
    position: fixed;
    pointer-events: all;
  }

  .project_page .project_page_content_container {
    padding-top: 70vh;
    /* Adjust as needed to fit your design */
  }

  .project_page .project_content_container {
    display: block;
    /* Stack the content vertically */
    padding: 30px 20px;
  }

  .project_page .project_title_description {
    padding: 0;
    font-size: 1rem;
  }

  .project_page .project_details {
    padding: 25px 0;
  }

  .project_page .project_details .project_detail .mini_title {
    width: 75px;
  }

  .project_page .project_details .project_detail .mini_title,
  .project_page .project_details .project_detail .detail_content,
  .project_page .project_details .project_detail p {
    font-size: 1rem;
  }

  .project_page .content_body {
    padding: 20px 0;
  }

  .project_page .images_grid {
    padding: 0 20px;
    grid-template-columns: 1fr;
    row-gap: 10px;
    column-gap: 0;
  }

  .project_page .navigation {
    font-size: 1.2rem;
  }

  .project_page .image_popup .navigation {
    position: absolute;
    display: flex;
    gap: 150px;
    top: 80%;
    transform: none;
  }

  .project_page .image_popup .navigation.hidden {
    display: none;
  }

  .project_page .navigation.right {
    right: 25%;
  }

  .project_page .navigation.left {
    left: 25%;
  }

  .project_page .contact_form {
    padding: 50px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .project_page .page_sub_title {
    font-size: 2.2rem;
    line-height: 2.7rem;
  }
}