.news_item_body {
    position: relative;
    width: 100%;
    padding: 25px 0;
    display: flex;
    flex-direction: column;
}

.news_item_body .img_full_width {
    width: 100%;
}

.news_item_body .paragraph {
    width: 100%;
    padding: 25px 0;
}

.news_item_body .quote {
    width: 100%;
    line-height: 1.7rem;
    padding: 35px;
    border-left: 2px solid var(--secondairy-light-gray);
    background-color: var(--secondairy-ultra-light-gray);
    font-style: italic;
}

.news_item_body .quote.right {
    width: 50%;
    border-left: none;
    border-right: 2px solid var(--secondairy-light-gray);
    align-self: flex-end;
}

.news_item_body .quote.left {
    width: 50%;
    align-self: flex-end;
}

.news_item_body .content_title {
    width: 100%;
    padding: 35px 0;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--primary-color);
    text-align: center;
}

.news_item_body .sub_title {
    width: 100%;
    padding: 35px 0;
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--primary-color);
}

@media only screen and (max-width: 768px) {

    .news_item_body .quote.right {
        width: 70%;
    }
    
    .news_item_body .quote.left {
        width: 70%;
    }

}