.cookie-accepter {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: white;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    z-index: 1000;
    font-family: Arial, sans-serif;
    border-radius: 10px;
}

.cookie-message {
    margin-bottom: 15px;
}

.cookie-message p {
    margin: 0 0 10px 0;
    font-size: 14px;
    line-height: 1.5;
    color: var(--secondairy-medium-gray);
}

.cookie-message a {
    color: var(--primary-color-light);
    text-decoration: none;
}

.cookie-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: transparent;
    color: var(--secondairy-medium-gray);
    border: var(--secondairy-medium-gray) 2px solid;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    border-radius: 5px;
    width: 100%;
}

.cookie-button:hover {
    background-color: var(--secondairy-medium-gray);
    color: white;
}

@media only screen and (max-width: 768px) {

    .cookie-accepter {
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
    }

}