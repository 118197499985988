.news_page_body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.news_page_body .main_image {
  position: relative;
  width: 100vw;
  height: 45vh;
}

.news_page_body .main_image .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.10;
}

.news_page_body .main_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.news_page_body .content {
  width: 100%;
  padding: 50px 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.news_page_body .content h1 {
  text-align: center;
  margin: 20px 0;
  width: 100%;
  font-weight: 400;
  font-style: italic;
  font-size: 2.3rem;
  color: var(--primary-color);
}

.news_page_body .content>p {
  width: auto;
  border-bottom: 1px solid var(--secondairy-medium-gray);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.news_page_body .content .main_paragraph {
  width: 100%;
  line-height: 1.7rem;
  padding: 35px 0;
}

@media only screen and (max-width: 768px) {

  .news_page_body .content {
    padding: 50px 25px;
  }

}