#legal-page .content_page__content {
    width: 60%;
}

#legal-page .sub_nav {
    display: flex;
    justify-content: space-between;
    color: var(--primary-colorµ);
    font-weight: 500;
    margin-bottom: 50px;
    cursor: pointer;
}

#legal-page .paragraph {
    width: 100%;
    padding: 25px 0;
}

#legal-page .sub_title {
    width: 100%;
    padding: 35px 0;
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--primary-color);
}

@media only screen and (max-width: 768px) {

    #legal-page .content_page__content {
        width: 100%;
        max-width: calc(100vw - 50px);
    }    

    .content_page_body .page_title {
        margin: 125px 0 50px 0;
    }

    #legal-page .sub_nav {
        flex-direction: column;
        gap: 25px;
        align-items: center;
    }

}